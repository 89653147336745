import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@clearscore-group/lib.redux.prospect';

const useCreateProspect = () => {
    const dispatch = useDispatch();
    const handleSubmit = (data: { email: string }) => dispatch(actions.register(data));
    const { isPending } = useSelector<Record<string, unknown>, { fetch: { isPending: boolean } }>(
        selectors.getPredicate,
    ).fetch;

    return {
        isLoading: isPending,
        handleSubmit,
    };
};

export default useCreateProspect;
