import React from 'react';
import Text from '@clearscore/ui.rainbow.text';
import Accordion from '@clearscore/ui.rainbow.accordion';
import List from '@clearscore/ui.rainbow.list';
import Spacer from '@clearscore/ui.rainbow.spacer';
import Button from '@clearscore/ui.rainbow.button';
import PropTypes from 'prop-types';

import styles from './faq-section.module.css';

const FAQSection = ({ language, simplifiedFaq }) => (
    <section className={styles.faqSection}>
        <div className={styles.faqLists}>
            <div className={styles.title}>
                <Text dataId="hero-title" type="LARGE" weight="BOLD" tag="h3">
                    FAQs
                </Text>
            </div>
            <div className={styles.faq}>
                <Accordion title={language.faqSection.section1Title}>
                    <div className={styles.accordionContent}>
                        <Accordion.Content>
                            <Text.Body2>{language.faqSection.section1Content}</Text.Body2>
                        </Accordion.Content>
                    </div>
                </Accordion>
            </div>
            <div className={styles.faq}>
                <Accordion title={language.faqSection.section2Title}>
                    <div className={styles.accordionContent}>
                        <Accordion.Content>
                            <Text.Body2>{language.faqSection.section2Content}</Text.Body2>
                            {!simplifiedFaq && (
                                <List>
                                    <List.Item>
                                        <Spacer all={{ top: Spacer.spacings.MEDIUM }}>
                                            <Text.Body2>{language.faqSection.section2ContentBullet1}</Text.Body2>
                                        </Spacer>
                                    </List.Item>
                                    <List.Item>
                                        <Spacer all={{ top: Spacer.spacings.MEDIUM }}>
                                            <Text.Body2>{language.faqSection.section2ContentBullet2}</Text.Body2>
                                        </Spacer>
                                    </List.Item>
                                    <List.Item>
                                        <Spacer all={{ top: Spacer.spacings.MEDIUM }}>
                                            <Text.Body2>{language.faqSection.section2ContentBullet3}</Text.Body2>
                                        </Spacer>
                                    </List.Item>
                                </List>
                            )}
                        </Accordion.Content>
                    </div>
                </Accordion>
            </div>
            <div className={styles.faq}>
                <Accordion title={language.faqSection.section3Title} isInitialExpanded>
                    <div className={styles.accordionContent}>
                        <Accordion.Content>
                            <Text.Body2>{language.faqSection.section3Content}</Text.Body2>
                            {!simplifiedFaq && (
                                <Spacer>
                                    <List>
                                        <List.Item>
                                            <Spacer all={{ top: Spacer.spacings.MEDIUM }}>
                                                <Text.Body2 weight="BOLD">
                                                    {language.faqSection.section3ListTitle1}
                                                </Text.Body2>
                                                <Text.Body2>{language.faqSection.section3ListItem1}</Text.Body2>
                                            </Spacer>
                                        </List.Item>
                                    </List>
                                    <List>
                                        <List.Item>
                                            <Spacer all={{ top: Spacer.spacings.MEDIUM }}>
                                                <Text.Body2 weight="BOLD">
                                                    {language.faqSection.section3ListTitle2}
                                                </Text.Body2>

                                                <Text.Body2>{language.faqSection.section3ListItem2}</Text.Body2>
                                            </Spacer>
                                        </List.Item>
                                    </List>
                                </Spacer>
                            )}
                        </Accordion.Content>
                    </div>
                </Accordion>
            </div>
            <div className={styles.faq}>
                <Accordion title={language.faqSection.section4Title}>
                    <div className={styles.accordionContent}>
                        <Accordion.Content>
                            <Text.Body2>{language.faqSection.section4Content}</Text.Body2>
                        </Accordion.Content>
                    </div>
                </Accordion>
            </div>
            <div className={styles.ctaContainer}>
                <Button href={language.ctaUrl} size={Button.sizes.LARGE} type={Button.types.PRIMARY} isResponsive>
                    {language.signUpCtaText}
                </Button>
            </div>
        </div>
    </section>
);

FAQSection.propTypes = {
    simplifiedFaq: PropTypes.bool.isRequired,
    language: PropTypes.shape({
        signUpCtaText: PropTypes.string,
        ctaUrl: PropTypes.string,
        faqSection: PropTypes.shape({
            section1Title: PropTypes.string,
            section1Content: PropTypes.string,
            section2Title: PropTypes.string,
            section2Content: PropTypes.string,
            section2ContentBullet1: PropTypes.string,
            section2ContentBullet2: PropTypes.string,
            section2ContentBullet3: PropTypes.string,
            section3Title: PropTypes.string,
            section3Content: PropTypes.string,
            section3ListTitle1: PropTypes.string,
            section3ListItem1: PropTypes.string,
            section3ListTitle2: PropTypes.string,
            section3ListItem2: PropTypes.string,
            section4Title: PropTypes.string,
            section4Content: PropTypes.string,
        }),
    }).isRequired,
};

export default FAQSection;
