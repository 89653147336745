import React from 'react';
import Template from '@clearscore/shared.website-template';
import PropTypes from 'prop-types';
import useLanguage from '@clearscore-group/lib.hooks.use-language';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';

import HeroSection from './components/hero-section';
import FeatureSection from './components/feature-section';
import FAQSection from './components/faq-section';
import AppFooter from './components/app-footer';
import styles from './credit-report-landing.module.css';
import marketConfig from './lib/market-config';
import lang from './lib/lang';

const CreditReportLandingPage = ({ appStoreConfig, cookiePolicyConfig, images }) => {
    const { langFlag, simplifiedFaq } = useMarketConfig(marketConfig);
    const language = useLanguage(lang, langFlag);

    return (
        <Template appStoreConfig={appStoreConfig} cookiePolicyConfig={cookiePolicyConfig} pullUpContent>
            <div className={styles.cards}>
                <div className={styles.sectionContainer}>
                    <HeroSection images={images} hasEmailSignup={false} hasScrollIndicator language={language} />
                    <FeatureSection images={images} language={language} />
                    <FAQSection language={language} images={images} simplifiedFaq={simplifiedFaq} />
                    <AppFooter language={language} images={images} appStoreConfig={appStoreConfig} />
                </div>
            </div>
        </Template>
    );
};

CreditReportLandingPage.propTypes = {
    appStoreConfig: PropTypes.shape({
        googlePlayUrl: PropTypes.string,
        appStoreUrl: PropTypes.string,
        appStoreLink: PropTypes.string,
        playStoreLink: PropTypes.string,
        appleStoreToggle: PropTypes.bool,
        googleStoreToggle: PropTypes.bool,
    }).isRequired,
    cookiePolicyConfig: PropTypes.shape({
        moreInfoLinkUrl: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
    }).isRequired,
    images: PropTypes.shape({
        heroImage: PropTypes.node.isRequired,
        featureWeekly: PropTypes.node.isRequired,
        featureImprove: PropTypes.node.isRequired,
        featureNumberOne: PropTypes.node.isRequired,
        featureExclusive: PropTypes.node.isRequired,
        donutDesktop: PropTypes.node.isRequired,
        donut: PropTypes.node.isRequired,
        donutV2: PropTypes.node.isRequired,
        clearscoreLogo: PropTypes.node.isRequired,
        appStoreLogo: PropTypes.node.isRequired,
        googlePlayLogo: PropTypes.node.isRequired,
    }).isRequired,
};

export default CreditReportLandingPage;
