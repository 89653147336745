/* eslint-disable camelcase, react/prop-types */
import React from 'react';
import type { ReactElement } from 'react';
import { graphql } from 'gatsby';
import type { PageProps } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import type { ImageDataLike } from 'gatsby-plugin-image';
import CreditScore from '@clearscore/website.credit-report-landing';

import avatar from '../assets/global/credit-cards/credit-card-landingpage-250x250.png';
import Layout from '../components/layout';

interface DataReport {
    heroImage: ImageDataLike;
    featureExclusive: ImageDataLike;
    featureImprove: ImageDataLike;
    featureNumberOne: ImageDataLike;
    featureWeekly: ImageDataLike;
    donut: ImageDataLike;
    clearscoreLogo: ImageDataLike;
    appStoreLogo: ImageDataLike;
    googlePlayLogo: ImageDataLike;
}

const CreditReportTemplate = (props: PageProps<DataReport>): ReactElement => {
    const { data, location } = props;

    const images = {
        heroImage: getImage(data.heroImage),
        featureExclusive: getImage(data.featureExclusive),
        featureImprove: getImage(data.featureImprove),
        featureNumberOne: getImage(data.featureNumberOne),
        featureWeekly: getImage(data.featureWeekly),
        donut: getImage(data.donut),
        clearscoreLogo: getImage(data.clearscoreLogo),
        appStoreLogo: getImage(data.appStoreLogo),
        googlePlayLogo: getImage(data.googlePlayLogo),
    };

    return (
        <Layout
            meta={{
                title: 'Credit scores with ClearScore | check your credit score for free',
                description: 'Get your credit score and credit report, for free, forever.',
                image: avatar,
            }}
            location={location.pathname}
        >
            {({ commonModules }): ReactElement => (
                <CreditScore
                    appStoreConfig={commonModules.app_store}
                    cookiePolicyConfig={commonModules.cookie_policy}
                    images={images}
                />
            )}
        </Layout>
    );
};

export const Head = () => <meta id="robots" name="robots" content="noindex" />;

export const query = graphql`
    query ReportTemplateSignup {
        heroImage: file(absolutePath: { regex: "/credit-report-signup/heroImage.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                )
            }
        }
        featureExclusive: file(absolutePath: { regex: "/credit-report-signup/feature-exclusive.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    placeholder: TRACED_SVG
                )
            }
        }
        featureImprove: file(absolutePath: { regex: "/credit-report-signup/feature-improve.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    placeholder: TRACED_SVG
                )
            }
        }
        featureNumberOne: file(absolutePath: { regex: "/credit-report-signup/feature-number-one.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    placeholder: TRACED_SVG
                )
            }
        }
        featureWeekly: file(absolutePath: { regex: "/credit-report-signup/feature-weekly.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    placeholder: TRACED_SVG
                )
            }
        }
        donut: file(absolutePath: { regex: "/credit-report-signup/donut.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    placeholder: TRACED_SVG
                )
            }
        }
        clearscoreLogo: file(absolutePath: { regex: "/credit-report-signup/clearscore-logo.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        appStoreLogo: file(absolutePath: { regex: "/credit-report-signup/app-store-logo.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        googlePlayLogo: file(absolutePath: { regex: "/credit-report-signup/google-play-store.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
    }
`;

export default CreditReportTemplate;
