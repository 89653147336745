import React from 'react';
import PropTypes from 'prop-types';
import AppStoreLogos from '@clearscore/shared.website-app-logos';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';

import marketConfig from '../../lib/market-config';
import styles from './app-footer.module.css';

const AppFooter = ({ language, appStoreConfig }) => {
    const { hideFooterPitch } = useMarketConfig(marketConfig);
    return (
        <section className={styles.section} data-qa="app-footer">
            <div className={styles.footerContainer}>
                {!hideFooterPitch ? (
                    <React.Fragment>
                        <div className={styles.pitch}>{language.footerPitch}</div>
                        <div className={styles.storeLogos}>
                            <AppStoreLogos panel="footer" appStoreConfig={appStoreConfig} />
                        </div>
                    </React.Fragment>
                ) : null}
            </div>
        </section>
    );
};

AppFooter.propTypes = {
    language: PropTypes.node.isRequired,
    appStoreConfig: PropTypes.shape({
        googlePlayUrl: PropTypes.string,
        appStoreUrl: PropTypes.string,
        appStoreLink: PropTypes.string,
        playStoreLink: PropTypes.string,
        appleStoreToggle: PropTypes.bool,
        googleStoreToggle: PropTypes.bool,
    }).isRequired,
};
export default AppFooter;
