import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import Text from '@clearscore/ui.rainbow.text';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';
import Button from '@clearscore/ui.rainbow.button';
import cx from 'classnames';
import CreateProspectForm from '@clearscore/shared.website-create-prospect-form';

import marketConfig from '../../lib/market-config';
import styles from './hero.module.css';
import useCreateProspect from '../../hooks/use-create-prospect';

const buttonThemes = Button.themes;

const Hero = ({ language, images, reference }) => {
    const { alternateFeatureHeadings, alternateCreditScoreLogo, showProspectForm, localisedDonut } =
        useMarketConfig(marketConfig);
    const { isLoading, handleSubmit } = useCreateProspect();

    const donutLogo = alternateCreditScoreLogo ? images.donutV2 : images.donut;
    const donutImage = localisedDonut ? images.donutAu : donutLogo;

    return (
        <section className={cx(styles.section, styles.hero)} data-qa="hero" ref={reference}>
            <GatsbyImage style={{ position: 'unset' }} image={images.heroImage} alt="" />
            <div className={styles.sectionContent}>
                <div className={styles.heroContent}>
                    <div className={styles.heroIntro}>
                        <h1 className={styles.title}>{language.heading}</h1>
                        <p className={styles.subHeading}>{language.subHeading}</p>

                        {showProspectForm ? (
                            <div className={styles.createProspectForm}>
                                <CreateProspectForm
                                    theme={CreateProspectForm.themes.DARK}
                                    isLightBg
                                    isLoading={isLoading}
                                    onSubmit={handleSubmit}
                                    language={{
                                        submitCta: language.signUpCtaText,
                                    }}
                                />
                            </div>
                        ) : (
                            <Button
                                href={language.ctaUrl}
                                size={Button.sizes.LARGE}
                                type={Button.types.PRIMARY}
                                isNarrow
                                theme={buttonThemes.DARK}
                            >
                                {language.signUpCtaText}
                            </Button>
                        )}
                    </div>
                    <div className={styles.donutDesktopContainer}>
                        <div className={styles.donutDesktopImage}>
                            <GatsbyImage image={donutImage} alt="" />
                        </div>
                    </div>
                </div>
                <div className={styles.featureLists}>
                    <div className={styles.featureContainer}>
                        {alternateFeatureHeadings
                            ? language.alternateFeatureHeadings.map((feature) => (
                                  <div className={styles.featureItem} key={feature.key}>
                                      <div className={styles.featureIcon}>
                                          <GatsbyImage
                                              image={images[feature.key]}
                                              alt=""
                                              className={styles.featureImage}
                                          />
                                      </div>

                                      <div className={styles.featureTitle}>
                                          <Text type="MINI" spacing="TIGHT" tag="p">
                                              {feature.title}
                                          </Text>
                                      </div>
                                  </div>
                              ))
                            : language.features.map((feature) => (
                                  <div className={styles.featureItem} key={feature.key}>
                                      <div className={styles.featureIcon}>
                                          <GatsbyImage
                                              image={images[feature.key]}
                                              alt=""
                                              className={styles.featureImage}
                                          />
                                      </div>

                                      <div className={styles.featureTitle}>
                                          <Text type="MINI" spacing="TIGHT" tag="p">
                                              {feature.title}
                                          </Text>
                                      </div>
                                  </div>
                              ))}

                        <div className={cx(styles.donutMobileContainer, { [styles.shiftUp]: showProspectForm })}>
                            <GatsbyImage image={donutImage} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

Hero.propTypes = {
    language: PropTypes.node.isRequired,
    images: {
        heroImage: PropTypes.node.isRequired,
        featureWeekly: PropTypes.node.isRequired,
        featureImprove: PropTypes.node.isRequired,
        featureNumberOne: PropTypes.node.isRequired,
        featureExclusive: PropTypes.node.isRequired,
        donut: PropTypes.node.isRequired,
    }.isRequired,
    reference: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

Hero.defaultProps = {
    reference: null,
};

export default Hero;
