import { EN_GB, EN_AU, EN_ZA, EN_CA, EN_NZ } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_AU]: {
        section: 'hero',
        heading: 'Your credit score & report. Always free, forever.',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-au',
        // eslint-disable-next-line max-len
        appInfo:
            'Your credit score is a number out of 1000. It’s based on your credit report, which is a record of how you’ve managed credit in the past. We give you both of these for free, making it as easy as possible for you to start improving it.',
        subHeading:
            'It takes just 3 minutes to join the 20 million people who already trust ClearScore to help them improve their financial future.',
        signUpCtaText: 'Get your credit score',
        features: [
            {
                key: 'featureWeekly',
                title: 'Your free credit score & report, updated monthly',
                content: [
                    'Unlike some other providers, when we say free, we mean forever.  No 30 day trials, we give you your credit score and report for free, forever.',
                    'And because keeping up to date is so important, we show you your new report every single week.',
                ],
            },
            {
                key: 'featureImprove',
                title: 'Help and support improving your score',
                content: [
                    'You’ll see clear, personalised insights about your credit score and tips on how to improve it. By improving your credit score, you have a better chance of getting better credit offers and saving money.',
                ],
            },
            {
                key: 'featureNumberOne',
                title: 'Australians love ClearScore',
                content: [
                    'Over 20 million people globally already trust ClearScore to help them with their financial future.',
                ],
            },
            {
                key: 'featureExclusive',
                title: 'Compare credit offers and see your approval odds',
                content: [
                    'The higher your credit score, the more likely you are to see credit card and loan offers. You could even get lower interest rates and save money.  We bring you exclusive offers with your approval odds, helping you feel clearer about credit.',
                ],
            },
        ],
        alternateFeatureHeadings: [
            {
                key: 'featureWeekly',
                title: 'Your free credit score & report, updated monthly',
            },
            {
                key: 'featureImprove',
                title: 'Help and support improving your score',
            },
            {
                key: 'featureNumberOne',
                title: 'Join over 20 million happy ClearScore members',
            },
            {
                key: 'featureExclusive',
                title: 'See your approval chances before applying for credit',
            },
        ],
        footerPitch:
            'Download the latest version of the Clearscore App from the App Store (iOS) or Play Store (Android).',
        faqSection: {
            section1Title: 'Here’s how we get your credit report',
            section1Content:
                'In Australia, we provide access to your credit reports and scores using illion and Experian, two of Australia’s credit bureaus. Both credit scores are out of 1,000.',
            section2Title: 'How much does it cost?',
            section2Content:
                'Nothing. With ClearScore, there are no trials. Your credit report and score is free, forever. That’s ClearScore.',
            section2ContentBullet1: 'See your credit score for free',
            section2ContentBullet2: 'Find out how to improve it',
            section2ContentBullet3: 'Apply for offers tailored to you',
            section3Title: 'How is my credit report free?',
            section3Content:
                "This is all free because we are paid by lenders. When you apply for a credit card or loan, lenders pay us a commission. But don't worry, we rank offers based on what's best for you, not on commission.",
            section3ListTitle1: 'Correct mistakes on your report',
            section3ListItem1:
                'Sometimes credit reference agencies have wrong information about you, which can harm your credit score. Fix any mistakes so you’re in the best position when you apply for credit.',
            section3ListTitle2: 'Make sure nobody has stolen your identity.',
            section3ListItem2:
                'Your report shows you if any fraudsters have opened up cards or loans in your name. If they have, you can contact your lenders and the authorities. You can also use ClearScore Protect, our dark web scan, to look after your identity online.',

            section4Title: 'How do offers on ClearScore work?',
            section4Content: 'We use your credit profile to find financial products that might be a good fit for you.',
        },
    },
    [EN_NZ]: {
        section: 'hero',
        heading: 'Your credit score & report. For free, forever.',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-nz',
        // eslint-disable-next-line max-len
        appInfo:
            'Your credit score is a number out of 1000. It’s based on your credit report, which is a record of how you’ve managed credit in the past. We give you both of these for free, making it as easy as possible for you to start improving it.',
        subHeading:
            'It takes just 2 minutes to join the 200,000 Kiwis who already trust ClearScore to help them improve their financial future.',
        signUpCtaText: 'Get your credit score',
        features: [
            {
                key: 'featureWeekly',
                title: 'Your free credit score & report, updated monthly',
                content: [
                    'Unlike some other providers, when we say free, we mean forever.  No 30-day trials, we give you your credit score and report for free, forever.',
                ],
            },
            {
                key: 'featureImprove',
                title: 'Help and support for improving your score',
                content: [
                    'Get access to tips and insights to improve your credit score. A better score can increase your chances of accessing better credit offers and save you money.',
                ],
            },
            {
                key: 'featureNumberOne',
                title: 'Kiwis love ClearScore',
                content: ['Over 200,000 Kiwis already trust ClearScore to help them with their financial future.'],
            },
            {
                key: 'featureExclusive',
                title: 'Compare credit offers that suit your needs',
                content: [
                    'The higher your credit score, the better the finance that might be available to you. You could even get lower interest rates and save money. We bring you offers from trusted lenders so you can compare before applying.',
                ],
            },
        ],
        alternateFeatureHeadings: [
            {
                key: 'featureWeekly',
                title: 'Your free credit score & report, updated monthly',
            },
            {
                key: 'featureImprove',
                title: 'Help and support improving your score',
            },
            {
                key: 'featureNumberOne',
                title: 'Join over 200,000 happy Kiwis',
            },
            {
                key: 'featureExclusive',
                title: 'Compare credit products before you apply without harming your credit score',
            },
        ],
        footerPitch:
            'Download the latest version of the Clearscore App from the App Store (iOS) or Play Store (Android).',
        faqSection: {
            section1Title: 'Here’s how we get your credit report',
            section1Content:
                'In New Zealand, we provide access to your credit report and score through illion, one of the credit bureaus. Your credit score is out of 1,000.',
            section2Title: 'How much does it cost?',
            section2Content:
                'Nothing. With ClearScore, there are no trials. Your credit report and score is free, forever. That’s ClearScore.',
            section2ContentBullet1: 'See your credit score for free',
            section2ContentBullet2: 'Find out how to improve it',
            section2ContentBullet3: 'Apply for offers tailored to you',
            section3Title: 'How is my credit report free?',
            section3Content:
                "This is all free because we are paid by lenders. If you apply for a credit card or loan, lenders pay us a commission. But don't worry, we never rank based on commission.",
            section3ListItem1:
                'Sometimes credit reference agencies have incorrect information about you which can harm your credit score. Fix any mistakes so you’re in the best position when you apply for credit.',
            section3ListTitle2: 'Make sure nobody has stolen your identity.',
            section3ListItem2:
                'Your report shows you if any fraudsters have opened up cards or loans in your name. If they have, you can contact your lenders and the authorities. You can also use ClearScore Protect, our dark web scan, to look after your identity online.',
            section4Title: 'How do offers on ClearScore work?',
            section4Content:
                'We show you credit offers across credit cards and loans that you may qualify for. You can compare financial products before applying, without impacting your score.',
        },
    },
    [EN_GB]: {
        section: 'hero',
        heading: 'Your credit score & report. Always free, forever.',
        ctaUrl: 'https://app.clearscore.com/signup',
        // eslint-disable-next-line max-len
        subHeading:
            'It takes just 2 mins to join the 20+ million people who already trust ClearScore to help them improve their financial future.',
        signUpCtaText: 'Sign Up Free',
        features: [
            {
                key: 'featureWeekly',
                title: 'Your free credit score & report, updated weekly',
                content: [
                    'Unlike some other providers, when we say free, we mean forever.  No 30 day trials, we give you your credit score and report for free, forever. ',
                    'And because keeping up to date is so important, we show you your new report every single week. ',
                ],
            },
            {
                key: 'featureImprove',
                title: 'Help and support improving your score',
                content: [
                    'You’ll see clear, personalised insights about your credit score and tips on how to improve it. By improving your credit score, you have a better chance of getting better credit offers and saving money.',
                ],
            },
            {
                key: 'featureNumberOne',
                title: 'The UK’s number 1 free credit score and report app',
                content: [
                    // eslint-disable-next-line max-len
                    'Over 20+ million people already trust ClearScore to help them with their financial future.',
                ],
            },
            {
                key: 'featureExclusive',
                title: 'Exclusive, pre-approved credit offers, personalised to you',
                content: [
                    'The higher your credit score, the more likely you are to see credit card and loan offers. You could even get lower interest rates and save money. We bring you exclusive offers you won’t see anywhere else, and you could even be pre-approved, helping you feel clearer about credit.',
                ],
            },
        ],
        alternateFeatureHeadings: [
            {
                key: 'featureWeekly',
                title: 'Your free credit score & report, updated weekly',
            },
            {
                key: 'featureImprove',
                title: 'Help and support improving your score',
            },
            {
                key: 'featureNumberOne',
                title: 'Join over 20 million happy ClearScore members',
            },
            {
                key: 'featureExclusive',
                title: 'See your approval chances before applying for credit',
            },
        ],
        faqSection: {
            section1Title: 'What is a credit score?',
            section1Content:
                'Your credit score is a number out of 1000. It’s based on your credit report, which is a record of how you’ve managed credit in the past. We give you both of these for free, making it as easy as possible for you to start improving it.',
            section2Title: 'How can my credit score help me?',
            section2Content:
                'The higher your credit score, the more likely you are to be eligible for a credit card or loan. You could even get lower interest rates after improving it. Make the most out of ClearScore with three simple steps:',
            section2ContentBullet1: 'See your credit score for free',
            section2ContentBullet2: 'Find out how to improve it',
            section2ContentBullet3: 'Apply for offers tailored to you',
            section3Title: 'Why should I check my credit score?',
            section3Content:
                'Checking your credit score means you can take steps to improve it. This means you’re more likely to accepted for your next credit card or loan and get a wider range of deals. But there are two other great benefits to checking your score. You can:',
            section3ListTitle1: 'Correct mistakes on your report',
            section3ListItem1:
                'Sometimes credit reference agencies have wrong information about you, which can harm your credit score. Easily fix any mistakes so you’re in the best position when you apply for credit.',
            section3ListTitle2: 'Make sure nobody has stolen your identity.',
            section3ListItem2:
                'Your report shows you if any fraudsters have opened up cards or loans in your name. If they have, you can contact your lenders and the authorities. You can also use ClearScore Protect, our dark web scan, to look after your identity online.',
            section4Title: 'Will signing up damage my credit score?',
            section4Content: 'No. We will never do anything to impact your credit score.',
        },
        footerPitch:
            'We are the UK’s no.1 credit score and reporting app. Download the latest version of the Clearscore App from the App Store (iOS) or Play Store (Android).',
    },
    [EN_ZA]: {
        section: 'hero',
        heading: 'Check your credit score for free',
        ctaUrl: 'https://app.clearscore.com/signup',
        subHeading: 'Get your credit score and credit report, for free, forever',
        secondaryHeading: 'What is a credit score?',
        blurb: 'Find out how likely you are to be accepted for credit in seconds. Build your credit confidence and get a wider range of deals, all through ClearScore.',
        appInfo:
            'Your credit score is a number out of 740. It’s based on your credit report, which is a record of how you’ve managed credit in the past. We give you both of these for free, making it as easy as possible for you to start improving it.',
        tertiaryHeading: 'How can my credit score help me?',
        tertiaryInfo:
            'The higher your credit score, the more likely you are to be eligible for a credit card or loan. You could even get lower interest rates after improving it. Make the most out of ClearScore with three simple steps:',
        bullets: ['See your credit score for free', 'Find out how to improve it', 'Apply for offers tailored to you'],
        copyRight: '© Clear Score Technology Ltd. All Rights Reserved.',
        signUpCtaText: 'Sign Up Free',
    },
    [EN_CA]: {
        section: 'hero',
        heading: 'Your credit score & report. Always free, forever.',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-ca',
        // eslint-disable-next-line max-len
        appInfo:
            'Your credit score is a number out of 900. It’s based on your credit report, which is a record of how you’ve managed credit in the past. We give you both of these for free, making it as easy as possible for you to start improving it.',
        subHeading:
            'It takes just 3 mins to join the 20 million people who already trust ClearScore to help them improve their financial future.',
        signUpCtaText: 'Get your credit score',
        features: [
            {
                key: 'featureWeekly',
                title: 'Your free credit score & report, updated weekly',
                content: [
                    'Unlike some other providers, when we say free, we mean forever.  No 30 day trials, we give you your credit score and report for free, forever. ',
                    'And because keeping up to date is so important, we show you your new report every single week. ',
                ],
            },
            {
                key: 'featureImprove',
                title: 'Help and support improving your score',
                content: [
                    'You’ll see clear, personalized insights about your credit score and tips on how to improve it. By improving your credit score, you have a better chance of getting better credit offers and saving money.',
                ],
            },
            {
                key: 'featureNumberOne',
                title: 'Canadians love ClearScore',
                content: [
                    // eslint-disable-next-line max-len
                    'Over 20 million people globally already trust ClearScore to help them  with their financial future. ',
                ],
            },
            {
                key: 'featureExclusive',
                title: 'Compare credit offers and see your approval odds',
                content: [
                    'The higher your credit score, the more likely you are to see credit card and loan offers. You could even get lower interest rates and save money.  We bring you exclusive offers with your approval odds, helping you feel clearer about credit.',
                ],
            },
        ],
        alternateFeatureHeadings: [
            {
                key: 'featureWeekly',
                title: 'Your free credit score & report, updated weekly',
            },
            {
                key: 'featureImprove',
                title: 'Help and support improving your score',
            },
            {
                key: 'featureNumberOne',
                title: 'Join over 20 million happy ClearScore members',
            },
            {
                key: 'featureExclusive',
                title: 'See your approval chances before applying for credit',
            },
        ],
        footerPitch:
            'Download the latest version of the Clearscore App from the App Store (iOS) or Play Store (Android).',
        faqSection: {
            section1Title: 'What is a credit score?',
            section1Content:
                'Your credit score is a number out of 900. It’s based on your credit report, which is a record of how you’ve managed credit in the past. We give you both of these for free, making it as easy as possible for you to start improving it.',
            section2Title: 'How can my credit score help me?',
            section2Content:
                'The higher your credit score, the more likely you are to be eligible for a credit card or loan. You could even get lower interest rates after improving it. Make the most out of ClearScore with three simple steps:',
            section2ContentBullet1: 'See your credit score for free',
            section2ContentBullet2: 'Find out how to improve it',
            section2ContentBullet3: 'Apply for offers tailored to you',
            section3Title: 'Why should I check my credit score?',
            section3Content:
                'Checking your credit score means you can take steps to improve it. This means you’re more likely to accepted for your next credit card or loan and get a wider range of deals. But there are two other great benefits to checking your score. You can:',
            section3ListTitle1: 'Correct mistakes on your report',
            section3ListItem1:
                'Sometimes credit reference agencies have wrong information about you, which can harm your credit score. Fix any mistakes so you’re in the best position when you apply for credit.',
            section3ListTitle2: 'Make sure nobody has stolen your identity.',
            section3ListItem2:
                'Your report shows you if any fraudsters have opened up cards or loans in your name. If they have, you can contact your lenders and the authorities. You can also use ClearScore Protect, our dark web scan, to look after your identity online.',
            section4Title: 'Will signing up damage my credit score?',
            section4Content: 'No. We will never do anything to impact your credit score.',
        },
    },
};
