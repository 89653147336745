import { GB, ZA, AU, CA, EN_GB, EN_AU, EN_ZA, EN_CA, NZ, EN_NZ } from '@clearscore-group/lib.config.i18n';

export default {
    [GB]: {
        langFlag: EN_GB,
        alternateFeatureHeadings: false,
        hideFooterPitch: false,
        alternateCreditScoreLogo: false,
        showProspectForm: false,
        localisedDonut: false,
        simplifiedFaq: false,
    },
    [ZA]: {
        langFlag: EN_ZA,
        alternateFeatureHeadings: false,
        hideFooterPitch: false,
        alternateCreditScoreLogo: false,
        showProspectForm: false,
        localisedDonut: false,
        simplifiedFaq: false,
    },
    [AU]: {
        langFlag: EN_AU,
        alternateFeatureHeadings: true,
        hideFooterPitch: true,
        alternateCreditScoreLogo: false,
        showProspectForm: true,
        localisedDonut: true,
        simplifiedFaq: true,
    },
    [NZ]: {
        langFlag: EN_NZ,
        alternateFeatureHeadings: true,
        hideFooterPitch: true,
        alternateCreditScoreLogo: false,
        showProspectForm: true,
        localisedDonut: true,
        simplifiedFaq: true,
    },
    [CA]: {
        langFlag: EN_CA,
        alternateFeatureHeadings: true,
        hideFooterPitch: true,
        alternateCreditScoreLogo: true,
        showProspectForm: true,
        localisedDonut: false,
        simplifiedFaq: false,
    },
};
