import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Text from '@clearscore/ui.rainbow.text';
import Button from '@clearscore/ui.rainbow.button';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './feature-section.module.css';

const FeatureSection = ({ images, language }) => (
    <section className={styles.featureSection}>
        <div className={styles.featureLists}>
            {language.features.map((feature, index) => (
                <div
                    className={cx(
                        styles.featureItem,
                        index === language.features.length - 1 ? styles.featureItemLast : '',
                    )}
                    key={feature.key}
                >
                    <div className={styles.featureImageHolder}>
                        <GatsbyImage image={images[feature.key]} alt="" className={styles.featureImage} />
                    </div>
                    <div className={styles.featureContent}>
                        <div className={styles.featureTitle}>
                            <Text dataId="hero-title" type="LARGE" weight="BOLD">
                                {feature.title}
                            </Text>
                        </div>
                        <div className={styles.featureParagraph}>
                            {feature.content.map((paragraph, pindex) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Text.Body2 key={`${feature.key}-${pindex}`}>{paragraph}</Text.Body2>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
        <div className={styles.ctaContainer}>
            <Button href={language.ctaUrl} size={Button.sizes.LARGE} type={Button.types.PRIMARY} isResponsive>
                {language.signUpCtaText}
            </Button>
        </div>
    </section>
);
FeatureSection.propTypes = {
    language: PropTypes.shape({
        features: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
            }).isRequired,
        ).isRequired,
        signUpCtaText: PropTypes.string,
        ctaUrl: PropTypes.string,
    }).isRequired,
    images: PropTypes.shape({
        heroImage: PropTypes.node.isRequired,
        featureWeekly: PropTypes.node.isRequired,
        featureImprove: PropTypes.node.isRequired,
        featureNumberOne: PropTypes.node.isRequired,
        featureExclusive: PropTypes.node.isRequired,
    }).isRequired,
};

export default FeatureSection;
